import { useQuery } from 'react-query';
import graphqlRequestClient from '../graphqlRequestClient';
import { QueryKey } from './QueryKeys';
import {
	GetClientDataQuery,
	previewVariable,
} from '../../scripts/graphqlQueries';
import useSessionInfo from './useSessionInfo';

const useClientData = () => {
	const { isLoggedIn } = useSessionInfo();
	const { data } = useQuery(
		[QueryKey.GET_CLIENT_DATA],
		async () => {
			try {
				return await graphqlRequestClient.request(GetClientDataQuery, {
					...previewVariable,
				});
			} catch (error) {
				return error;
			}
		},
		{ enabled: !isLoggedIn }
	);

	return {
		clientCodes: data?.ashCareClientHomePageCollection?.items.map(
			item => item.clientCode
		),
		clientData: data?.ashCareClientHomePageCollection?.items,
		clientProgramSlugs: data?.ashCareClientHomePageCollection?.items.map(
			item => {
				const programSlugs = item.customProgramCardsCollection.items.map(
					program => program.overridePage?.slug || program.detailPage.slug
				);
				return {
					clientCode: item.clientCode,
					programSlugs,
				};
			}
		),
	};
};

export default useClientData;
