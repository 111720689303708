import { useQuery } from 'react-query';
import graphqlRequestClient from '../graphqlRequestClient';
import { QueryKey } from './QueryKeys';
import {
	GetCustomHomePageQuery,
	previewVariable,
} from '../../scripts/graphqlQueries';

const useCustomHomePage = (
	clientCode,
	clientCodes,
	isLoggedIn,
	pageId,
	programSlug
) => {
	const { data } = useQuery(
		[QueryKey.GET_CUSTOM_HOME_PAGE, clientCode],
		async () => {
			try {
				return await graphqlRequestClient.request(GetCustomHomePageQuery, {
					...previewVariable,
					clientCode,
				});
			} catch (error) {
				return error;
			}
		},
		{ enabled: !isLoggedIn && clientCodes?.includes(clientCode) }
	);

	const item = data?.ashCareClientHomePageCollection?.items.filter(
		client => client.clientCode === clientCode
	)[0];
	const customProgramCard = item?.customProgramCardsCollection?.items?.filter(
		program => {
			if (pageId)
				return (
					program?.overridePage?.sys?.id === pageId ||
					program?.detailPage?.sys?.id === pageId
				);
			if (programSlug)
				return (
					program?.overridePage?.slug === programSlug ||
					program?.detailPage?.slug === programSlug
				);
			return false;
		}
	)[0];

	return {
		colorLogo:
			customProgramCard?.customLogo?.colorLogo?.url ||
			item?.customLogo?.colorLogo?.url,
		colorLogoAlign:
			(customProgramCard?.customLogo?.logoAlignment ||
				item?.customLogo?.logoAlignment) ??
			'left',
		colorLogoXs:
			customProgramCard?.customLogoXs?.colorLogo?.url ||
			item?.customLogoXs?.colorLogo?.url,
		colorLogoXsAlign:
			(customProgramCard?.customLogoXs?.logoAlignment ||
				item?.customLogoXs?.logoAlignment) ??
			'left',
		isCobranded:
			customProgramCard?.customLogo?.isCobranded ||
			item?.customLogo?.isCobranded,
		altTextLogo:
			customProgramCard?.customLogo?.altText || item?.customLogo?.altText,
		phoneNumber: customProgramCard?.phoneNumber,
		pageTitle: customProgramCard?.pageTitle,
		pageDescription: customProgramCard?.pageDescription,
		salutationBanner: customProgramCard?.salutationBanner,
	};
};

export default useCustomHomePage;
