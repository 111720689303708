import { createContext, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { flushSync } from 'react-dom';

/**
 * Function to generate a new conversationSessionId
 * with the current timestamp. Date is for convenience
 * the component requires only an arbitrary and unique string
 * @returns {string} - The new conversationSessionId
 */
const newConversationSessionId = () => {
	const sessionId = `${new Date().getTime()}`;
	return sessionId;
};

/**
 * ChatbotContext is a context object that is used to
 * pass the conversationSessionId and resetChatHistory function
 */
export const ChatbotContext = createContext(null);

/**
 * Component for set up context providers for the entire application
 * @param {Object} children - The children components
 */
const AppContextProvider = ({ children }) => {
	const [conversationSessionId, setConversationSessionId] = useState(
		newConversationSessionId()
	);

	// Function to reset the chat history by changing the conversationSessionId
	const resetChatHistory = useCallback(
		() =>
			new Promise(resolve => {
				flushSync(() => {
					setConversationSessionId(newConversationSessionId());
				});
				resolve();
			}),
		[]
	);

	// Initialize memoized context value for ChatbotContext
	const chatbotConversationSessionValue = useMemo(
		() => ({
			conversationSessionId,
			resetChatHistory,
		}),
		[conversationSessionId, resetChatHistory]
	);

	return (
		<ChatbotContext.Provider value={chatbotConversationSessionValue}>
			{children}
		</ChatbotContext.Provider>
	);
};

AppContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AppContextProvider;
