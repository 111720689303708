import { useQuery } from 'react-query';
import { fetchFeatureFlags } from '../apiCalls';
import { QueryKey } from './QueryKeys';

// Query for the session info.
const useFeatureFlags = () => {
	// StartRegion: Set initial data to an empty object so that we don't have to use optional chaining if the data is not available.
	const { data, isError, isLoading } = useQuery(
		QueryKey.GET_FEATURE_FLAGS,
		fetchFeatureFlags
	);

	const { IntercomChat = false } = data;

	return {
		intercomChatEnabled: IntercomChat,
		isError,
		isLoading,
	};
};

export default useFeatureFlags;
