import dataService from './dataService';

export const fetchSessionInfo = async () => {
	try {
		return (await dataService.get('params')).data;
	} catch (error) {
		return error;
	}
};

export const fetchFeatureFlags = async () =>
	(await dataService.get('featureFlags')).data;

export const fetchEmailVerificationStatus = async () => {
	try {
		return (await dataService.get('identity/Email/Status')).data;
	} catch (error) {
		return error;
	}
};

export const fetchSsoRoute = async ({ queryKey }) => {
	const [, feature] = queryKey;

	try {
		return (await dataService.get(`outbound?feature=${feature}`)).data;
	} catch (error) {
		return error;
	}
};

export const resendVerificationEmail = async () =>
	(await dataService.post('identity/Email/Resend')).data;

export const fetchCareTeam = async () => {
	try {
		return (await dataService.get('careTeam')).data;
	} catch (error) {
		return error;
	}
};

export const updateWarmIntroStatus = async id =>
	(await dataService.post(`warmintro/${id}`)).data;
