import { useState, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { NavLinksType } from '../../../scripts/customPropTypes';
import Link from '@mui/material/Link';
import { Link as ReactLink, useLocation, useNavigate } from 'react-router-dom';
import { ChatbotContext } from '../AppContext';
import Button from '@mui/material/Button';

const UserProfileDropDown = ({ profileRoutes }) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const { resetChatHistory } = useContext(ChatbotContext);
	const open = Boolean(anchorEl);
	const { pathname } = useLocation();

	const handleOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogoutClick = async () => {
		await resetChatHistory();
		window.location.href = '/auth/logout'; // Manually handle the redirect it's not a react route
	};

	const selectMenuItem = link => {
		if (link.isReactPage) {
			return (
				<MenuItem
					component={ReactLink}
					to={link.href}
					reloadDocument={pathname === link.href}
					key={link.href}
				>
					{link.label}
				</MenuItem>
			);
		}

		if (link.href === '/auth/logout') {
			return (
				<MenuItem
					component={Button}
					key={link.href}
					data-testid={link.label}
					onClick={handleLogoutClick}
					sx={{ textTransform: 'none' }}
				>
					{link.label}
				</MenuItem>
			);
		}

		return (
			<MenuItem
				component={Link}
				href={link.href}
				key={link.href}
				data-testid={link.label}
			>
				{link.label}
			</MenuItem>
		);
	};

	return (
		<>
			<IconButton
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleOpen}
				color="inherit"
				aria-label="user profile drop down"
				data-testid="user-profile"
			>
				<PersonSharpIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{profileRoutes.map(link => selectMenuItem(link))}
			</Menu>
		</>
	);
};

UserProfileDropDown.propTypes = NavLinksType;

export default UserProfileDropDown;
