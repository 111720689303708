import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import HeaderLogo from './HeaderLogo';
import UserProfileDropDown from './UserProfileDropDown';
import MobileNavMenu from './MobileNavMenu';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import useNavRoutes from '../../../data/queryHooks/useNavRoutes';
import useIsMobile from '../../../utils/customHooks/useIsMobile';
import NavigationItem from './NavigationItem';
import {
	topBarHeight,
	topBarMobileHeight,
} from '../../../scripts/miscConstants';
import { NavButtonMapping } from '../buttons/NavButtons';

const TopBar = () => {
	const { topNavBarRoutes, profileRoutes, secondNavBarRoutes } = useNavRoutes();
	const isMobile = useIsMobile();

	// These only get added to the call to action button.
	const callToActionProps = {
		defaultStyles: {
			ml: 2,
		},
		color: 'secondary',
		variant: 'contained',
	};

	const normalButtonProps = {
		defaultStyles: {
			ml: 2,
			color: 'text.primary',
		},
	};

	return (
		<AppBar
			variant="outlined"
			elevation={0}
			color="inherit"
			sx={{ border: 'none', boxShadow: '0px 2px 2px -1px rgba(0, 0, 0, 0.07)' }}
		>
			<Container>
				<Toolbar
					sx={{
						justifyContent: 'space-between',
						minHeight: `${
							isMobile ? topBarMobileHeight : topBarHeight
						} !important`,
					}}
				>
					<Stack direction="row" spacing={1} sx={{ flexGrow: 1 }}>
						{isMobile && secondNavBarRoutes.length > 0 && (
							<MobileNavMenu navBarLinks={secondNavBarRoutes} />
						)}
						<HeaderLogo />
					</Stack>
					<Box>
						{topNavBarRoutes.length > 0 &&
							topNavBarRoutes.map(
								topNavBarLink =>
									// Call to action button is hidden for mobile screens since this
									// button will also be displayed on the home page.
									(!isMobile || topNavBarLink.isCallToAction) && (
										// @ts-ignore
										<NavigationItem
											key={topNavBarLink.href}
											navLink={topNavBarLink}
											{...(topNavBarLink.isCallToAction
												? callToActionProps
												: normalButtonProps)}
											{...NavButtonMapping}
										>
											{topNavBarLink.label}
										</NavigationItem>
									)
							)}
						{profileRoutes && (
							<UserProfileDropDown profileRoutes={profileRoutes} />
						)}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};

export default TopBar;
