import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import ServerStateProvider from './ServerStateProvider';
import Header from './header/Header';
import Footer from './footer/Footer';
import Stack from '@mui/material/Stack';
import useIsMobile from '../../utils/customHooks/useIsMobile';
import useNavRoutes from '../../data/queryHooks/useNavRoutes';
import { bool } from 'prop-types';
import useSessionInfo from '../../data/queryHooks/useSessionInfo';

const Layout = ({ showHeader, showNav, showFooter }) => {
	const { secondNavBarRoutes } = useNavRoutes();
	const { isLoggedIn } = useSessionInfo();
	const isMobile = useIsMobile();
	const margin = () => {
		if (showHeader && showNav) {
			return secondNavBarRoutes.length && !isMobile && isLoggedIn ? 19 : 9;
		}
		if (showHeader && !showNav) return 8;
		if (!showHeader && showNav)
			return secondNavBarRoutes.length && !isMobile ? 11 : 0;
		return 0;
	};

	return (
		<Stack
			sx={{
				minHeight: '100vh',
			}}
		>
			<Header showHeader={showHeader} showNav={showNav} />
			<Box
				component="main"
				sx={{
					mt: margin(),
					width: '100%',
					flexGrow: '1',
				}}
			>
				<ServerStateProvider>
					<Outlet />
				</ServerStateProvider>
			</Box>
			{showFooter && <Footer />}
		</Stack>
	);
};

Layout.propTypes = {
	showHeader: bool,
	showNav: bool,
	showFooter: bool,
};

export default Layout;
